import ButtonOutline from '@/components/atoms/Button/ButtonOutline'
import ItemSelector from '@/components/template/ItemSelector'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { Activity, CalculationMethodTreeNode, EmissionFactor, Scope, ScopeCategory } from '@/openapi/api'
import theme from '@/theme'
import { inputStatusToValidationColor } from '@/utils/siteSubmissionStatus'
import { InputTreeNode, InputTreeNodeEvents } from '@/utils/tree'
import { InputDate } from '@/zustand/slice/inputSlice'
import useStore from '@/zustand/sotre'
import React, { SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    margin-top: 30px;
    max-width: ${theme.contentContainer.width}px;
`

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
`

interface ItemSelector {
    activities: Activity[]
    emissionFactors: EmissionFactor[]
    onComplete?: () => void
    setActivities: React.Dispatch<SetStateAction<Activity[] | undefined>>
    scopeNumber: number
}
const CategorySelector = (props: Pick<Scope, 'categories'> & ItemSelector) => {
    const [selectedTree, setSelectedTree] = useState<InputTreeNode>()
    const {
        selectedCategory: selectedCategoryId,
        setSelectedCategory: setSelectedCategoryId,
        inputScopeState,
    } = useStore()
    const [showInputTree, setShowInputTree] = useState(false)
    const [scope3CategoryName, setScope3CategoryName] = useState('')
    const [currentTime] = useStateWithSessionStorage<InputDate | null>(SessionStorageKey.DATA_INPUT_DATE, null)

    useEffect(() => {
        if (selectedCategoryId) {
            const category = props.categories.find((c) => c.id === selectedCategoryId)
            if (category) handleClickButton(category)
        }
    }, [])

    useEffect(() => () => setSelectedCategoryId(3), [])

    const handleClickButton = (category: ScopeCategory) => {
        setSelectedCategoryId(category.id)
        setShowInputTree(false)
        setScope3CategoryName(category.name)

        const time = currentTime || undefined
        const tree = new InputTreeNode(category.calculationMethodTree as CalculationMethodTreeNode, time)
        const readyEventListener = () => {
            tree.target.removeEventListener(InputTreeNodeEvents.EMISSION_FACTORS_GET, readyEventListener)
            setShowInputTree(true)
        }
        tree.target.addEventListener(InputTreeNodeEvents.EMISSION_FACTORS_GET, readyEventListener)
        setSelectedTree(tree)
    }

    const onComplete = () => {
        if (selectedCategoryId && selectedCategoryId < 17) {
            handleClickButton(props.categories.find((c) => c.id == selectedCategoryId + 1)!)
        } else {
            setSelectedCategoryId(0)
            setShowInputTree(false)
        }
    }

    return (
        <>
            <Wrapper>
                <GridContainer>
                    {props.categories.map((category, index) => {
                        const activated =
                            selectedCategoryId === category.id
                                ? {
                                      background: theme.colors.primaryLight2,
                                      selectedBg: theme.colors.primaryN,
                                      selectedColor: theme.colors.white,
                                  }
                                : {}

                        const backgroundColor = inputStatusToValidationColor(inputScopeState.inputStatus[category.id])
                        const categoryName = category.name.split('：')
                        return (
                            <div key={index}>
                                <ButtonOutline
                                    style={{
                                        fontSize: '14px',
                                        width: '100%',
                                        height: 58,
                                        background: activated.selectedBg || backgroundColor,
                                        color: activated.selectedColor || theme.colors.primaryN,
                                        border: 'none',
                                    }}
                                    onClick={() => handleClickButton(category)}
                                >
                                    <div style={{ fontSize: 12, fontWeight: 300 }}>{categoryName[0]}</div>
                                    <div style={{ fontSize: 14, fontWeight: 300 }}>{categoryName[1] || ''}</div>
                                </ButtonOutline>
                            </div>
                        )
                    })}
                </GridContainer>
            </Wrapper>
            {showInputTree && selectedTree && selectedCategoryId && (
                <ItemSelector
                    tree={selectedTree}
                    categoryId={selectedCategoryId}
                    onComplete={onComplete}
                    activities={props.activities}
                    emissionFactors={props.emissionFactors}
                    setActivities={props.setActivities}
                    scope3categoryName={scope3CategoryName}
                    scopeNumber={props.scopeNumber}
                />
            )}
        </>
    )
}

export default CategorySelector
