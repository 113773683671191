import { InputStatus } from '@/ghgApi'
import theme from '@/theme'

export function inputStatusToValidationColor(status: InputStatus | null) {
    let backgroundColor: string
    switch (status) {
        case InputStatus.DONE:
            backgroundColor = theme.colors.expandBackground
            break
        case InputStatus.IN_PROGRESS:
            backgroundColor = theme.colors.expandBackground
            break
        case InputStatus.NOT_NEEDED:
            backgroundColor = theme.colors.accordionBackground
            break
        default:
            backgroundColor = theme.colors.accordionBackground
            break
    }
    return backgroundColor
}
