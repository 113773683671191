import { emissionFactorApi } from '@/ghgApi'
import { Activity, CalculationMethodTreeNode, EmissionFactor, TreeNodeLink } from '@/openapi/api'
import { InputDate } from '@/zustand/slice/inputSlice'
import groupby from 'lodash.groupby'

export enum InputTreeNodeEvents {
    EMISSION_FACTORS_GET = 'EmissionFactorsApi.getEmissionFactorTable',
}

export type GroupedActivities = { [index: number]: InputTreeActivity }

export type InputTreeActivity = {
    id?: Activity['id']
    categoryEmissionFactorTableId: Activity['categoryEmissionFactorTableId']
    emissionFactorId: Activity['emissionFactorId']
    quantity: Activity['quantity'] | null
    month?: Activity['month']
    memo?: Activity['memo']
    parentName?: string
}

export type InputTreeLeafNode = {
    id: number
    emissionFactorTableId: number
    name: string

    parent: InputTreeNode
    isSelected: boolean
    hasEmissionFactors: boolean

    emissionFactors: Array<EmissionFactor>
    selectedEmissionFactorIds: Set<EmissionFactor['id']>
    activities: Array<InputTreeActivity>
    usePreviousEmissionFactor: undefined | boolean
    title: string
    root: InputTreeNode
    nameBelowRoot: string

    link?: TreeNodeLink
    modalLinks?: TreeNodeLink[]
}

export class InputTreeNode {
    // Apiから取得できるAttributes
    name: string
    children?: Array<InputTreeNode>
    showInfo?: boolean
    modalLinks?: TreeNodeLink[]
    link?: TreeNodeLink
    description?: string

    // 追加Attributes
    parent: null | InputTreeNode
    target: Text
    _isSelected: boolean
    _hasEmissionFactors: boolean

    // LeafNodeのAttributes
    id: undefined | number
    emissionFactorTableId: undefined | number
    emissionFactors: undefined | Array<EmissionFactor>
    selectedEmissionFactorIds: null | Set<EmissionFactor['id']>
    activities: null | Array<InputTreeActivity>
    usePreviousEmissionFactor: undefined | boolean

    constructor(jsonNode: CalculationMethodTreeNode, time?: InputDate) {
        this.id = jsonNode.id
        this.emissionFactorTableId = jsonNode.emissionFactorTableId
        this.name = jsonNode.name
        this.showInfo = jsonNode.showInfo
        this.link = jsonNode.link
        this.modalLinks = jsonNode.modalLinks
        this.description = jsonNode.description
        this.usePreviousEmissionFactor = jsonNode.usePreviousEmissionFactor

        this.parent = null
        this.target = new Text('')
        this._isSelected = false
        this._hasEmissionFactors = false

        const isLeafNode = this.id && this.emissionFactorTableId
        if (isLeafNode) {
            this.emissionFactors = []
            this.selectedEmissionFactorIds = new Set()
            this.activities = []
        } else {
            this.emissionFactors = undefined
            this.selectedEmissionFactorIds = null
            this.activities = null
        }

        jsonNode.children?.forEach((c) => this._pushChild(new InputTreeNode(c, time), time))
    }

    _pushChild(node: InputTreeNode, time?: InputDate) {
        node.parent = this
        if (node.emissionFactorTableId && time) {
            const isGetDataPrev = [1, 2, 3].includes(time.month)
            const year = isGetDataPrev ? time.year - 1 : time.year
            emissionFactorApi
                .getEmissionFactorTable(node.emissionFactorTableId, !!node.usePreviousEmissionFactor ? year -1 : year)
                .then((res) => {
                    node.emissionFactors = res.data.emissionFactors
                    node.hasEmissionFactors = true
                    if (this.root.hasEmissionFactors) {
                        this.root.target.dispatchEvent(
                            new CustomEvent(InputTreeNodeEvents.EMISSION_FACTORS_GET, {
                                detail: { status: res.status },
                            }),
                        )
                    }
                })
                .catch((error) =>
                    this.root.target.dispatchEvent(
                        new CustomEvent(InputTreeNodeEvents.EMISSION_FACTORS_GET, { detail: { error } }),
                    ),
                )
        }

        if (!this.children) this.children = []
        this.children.push(node)
    }

    /**
     * 葉ノードであること
     */
    get isLeaf(): boolean {
        return Boolean(this.id && this.emissionFactorTableId)
    }

    /**
     * emissionFactorsの取得状態
     */
    get hasEmissionFactors(): boolean {
        return this._hasEmissionFactors
    }

    /**
     * APIからのデータ取得ができたがどうか
     * rootがisReadyの場合ツリー全体がisReady
     */
    set hasEmissionFactors(value: boolean) {
        this._hasEmissionFactors = value
        if (this.parent) {
            this.parent.hasEmissionFactors = this.siblings.every((c) => c.hasEmissionFactors)
        }
    }

    /**
     * ノードの選択状態を取得
     */
    get isSelected(): boolean {
        return this._isSelected
    }

    /**
     * ノードの選択状態を設定
     *
     * trueの場合は親ノードをtrueにする
     * falseの場合
     *   * 子ノード = false
     *   * activities = []
     *   * selectedEmissionFactorIds = new Set()
     */
    set isSelected(value: boolean) {
        this._isSelected = value
        if (this._isSelected) {
            if (this.parent) this.parent.isSelected = true
        } else {
            if (this.isLeaf) {
                this.activities = []
                this.selectedEmissionFactorIds = new Set()
            }
            this.children?.forEach((c) => (c.isSelected = false))
        }
    }

    /**
     * 入力完了状態を取得
     */
    get isComplete(): boolean {
        for (const leaf of this.root.getAllSelected()) {
            if (leaf.activities.length == 0) return false
            else
                for (const activity of leaf.activities) {
                    if (activity.quantity === null) return false
                }
        }
        return true
    }

    /**
     * ルートノードを取得
     */
    get root(): InputTreeNode {
        if (!this.parent) return this
        return this.parent.root
    }

    /**
     * 兄弟ノードを取得
     */
    get siblings(): Array<InputTreeNode> {
        if (!this.parent) return []
        else return this.parent.children!
    }

    /**
     * rootの真下のノードのnameを返す
     * this == rootの場合はundefinedを返す
     */
    get nameBelowRoot(): undefined | string {
        if (!this.parent) return undefined // rootの場合
        if (!this.parent.parent) return this.name // rootの真下の場合
        return this.parent.nameBelowRoot
    }

    /**
     * ノードのタイトルを取得
     */
    get title() {
        const separator = ' - '
        return this.nameBelowRoot == this.name ? this.name : this.nameBelowRoot + separator + this.name
    }

    getAllActivities(activities: InputTreeLeafNode['activities'] = []): InputTreeLeafNode['activities'] {
        if (this.activities) this.activities.forEach((a) => activities.push(a))
        this.children?.forEach((c) => c.getAllActivities(activities))
        return activities
    }

    handleSetIsSelected(activitie: InputTreeActivity, children: InputTreeNode) {
        children?.children?.forEach((item) => {
            if (activitie?.categoryEmissionFactorTableId === item.id) {
                item._isSelected = true
                //子カテゴリが選択されている場合、この子カテゴリを含む親カテゴリも選択されます
                children._isSelected = true
            }
            if (item?.children) this.handleSetIsSelected(activitie, item)
        })
    }

    setAllActivities(
        activities: Array<InputTreeActivity>,
        groupedActivities?: { [index: string]: InputTreeActivity[] },
    ) {
        if (!groupedActivities) groupedActivities = groupby(activities, (a) => a.categoryEmissionFactorTableId)
        if (this.isLeaf) {
            const newActivities = groupedActivities[this.id!]
            if (newActivities) {
                activities?.forEach((item) => {
                    if (item?.parentName && this.root.children) {
                        this.root.children?.forEach((chird) => {
                            if (item?.parentName === chird.name) chird._isSelected = true
                            if (chird?.children) this.handleSetIsSelected(item, chird)
                        })
                    }
                })
                this.activities = newActivities
                this.selectedEmissionFactorIds = new Set(newActivities.map((a) => a.emissionFactorId))
            }
        } else {
            this.children?.forEach((c) => c.setAllActivities(activities))
        }
    }

    getAllSelected(selected = [] as Array<InputTreeLeafNode>): Array<InputTreeLeafNode> {
        if (this.isSelected && this.emissionFactorTableId) selected.push(this as InputTreeLeafNode)
        this.children?.forEach((c) => c.getAllSelected(selected))
        return selected
    }

    setAllSelectedFromActivities(activities: Array<InputTreeActivity>, leafIds?: Set<InputTreeLeafNode['id']>) {
        if (!leafIds) leafIds = new Set(activities.map((a) => a.categoryEmissionFactorTableId))

        if (this.isLeaf && leafIds.has(this.id!)) this.isSelected = true
        this.children?.forEach((c) => c.setAllSelectedFromActivities(activities, leafIds))
    }

    setAllSelectedFromCalculationMethodsIds(calculationMethodIds: Set<number>) {
        this.isSelected = Boolean(this.id && calculationMethodIds.has(this.id))
        this.children?.forEach((child) => child.setAllSelectedFromCalculationMethodsIds(calculationMethodIds))
    }

    print(prefix = '') {
        console.log(prefix + this.name)
        this.children?.forEach((c) => c.print(prefix + '>'))
    }
}
