import React from 'react'

export default function MemoUnEnteredIconWhite() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16">
            <g id="file-earmark-text" transform="translate(-2)">
                <path id="パス_4300" data-name="パス 4300" d="M5.5,7a.5.5,0,0,0,0,1h5a.5.5,0,0,0,0-1ZM5,9.5A.5.5,0,0,1,5.5,9h5a.5.5,0,0,1,0,1h-5A.5.5,0,0,1,5,9.5Zm0,2a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,0,1h-2A.5.5,0,0,1,5,11.5Z" fill="#fff" />
                <path id="パス_4301" data-name="パス 4301" d="M9.5,0H4A2,2,0,0,0,2,2V14a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V4.5Zm0,1V3A1.5,1.5,0,0,0,11,4.5h2V14a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V2A1,1,0,0,1,4,1Z" fill="#fff" />
            </g>
        </svg>
    )
}
