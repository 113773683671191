import CustomCheckboxWithLabel from '@/components/atoms/Checkbox'
import Line from '@/components/atoms/Line'
import { useInputTreeContext } from '@/contexts/InputTree'
import theme from '@/theme'
import { InputTreeNode } from '@/utils/tree'
import { createStyles } from '@material-ui/core'
import makeStyles from '@material-ui/styles/makeStyles/makeStyles'
import React from 'react'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '&&:hover': {
                backgroundColor: 'transparent',
            },
        },
        expand: {
            transform: 'rotate(-90deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(0deg)',
        },
        customCheckbox: {
            margin: '0 35px',
            width: 'fit-content',
            minWidth: 120,
        },
        showLine: {
            position: 'relative',
            '&:not(:first-child):not(:last-child)': {
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: '-10px',
                    bottom: '0',
                    width: '3px',
                    right: 'auto',
                    left: '-26px',
                    borderLeft: '3px solid #c2c2c2',
                },
            },
        },
        haveChild: {
            position: 'relative',
            '&:before': {
                content: '""',
                position: 'absolute',
                width: '3px',
                height: '30px',
                left: '9px',
                top: '33px',
                borderLeft: '3px solid #c2c2c2',
            },
        },
    }),
)

const InputTreeNodeComponent = (props: { node: InputTreeNode; hideLine?: boolean }) => {
    const { inputTreeState, setInputTreeState } = useInputTreeContext()
    const classes = useStyles()

    const isDisabled = props.node.parent !== null && props.node.parent.name != 'root' && !props.node.parent.isSelected

    function handleChange() {
        props.node.isSelected = !props.node.isSelected

        const root = props.node.root
        setInputTreeState({ ...inputTreeState, tree: root, selected: root.getAllSelected() })
    }

    return (
        <div
            className={`${classes.customCheckbox} ${props.hideLine ? '' : classes.showLine} ${
                props.node.children ? classes.haveChild : ''
            }`}
        >
            <CustomCheckboxWithLabel
                checkboxProps={{
                    checked: props.node.isSelected,
                    disabled: isDisabled,
                    name: `${props.node.name}`,
                    onChange: handleChange,
                    value: props.node.isSelected,
                }}
                sizeLarge
                hideLine={props.hideLine}
                showInfo={props.node.showInfo}
                description={props.node.description}
                link={props.node.link}
                modalLinks={props.node.modalLinks}
            />
            {props.node.children &&
                props.node.children.map((child) => (
                    <InputTreeNodeComponent key={`${child.parent?.name}-${child.name}`} node={child} />
                ))}
        </div>
    )
}

export default function InputTree() {
    const { inputTreeState } = useInputTreeContext()

    return (
        <>
            <div style={{ marginTop: 25, fontSize: 16, fontWeight: 300 }}>算定方法から選ぶ</div>
            <Line />
            <div style={{ display: 'flex' }}>
                {inputTreeState.tree &&
                    inputTreeState.tree.children?.map((child) => {
                        return <InputTreeNodeComponent key={child.name} node={child} hideLine />
                    })}
            </div>
        </>
    )
}
