import theme from '@/theme'
import { ButtonProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyle = makeStyles({
    root: {
        border: `1px solid ${theme.colors.border2}`,
        borderRadius: '8px',
        width: 300,
        height: 80,
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background 0.3s ease-in-out',
    },
    active: {
        cursor: 'pointer',
        '&:hover': {
            background: '#ececec',
        },
    },
    disabled: {
        cursor: 'not-allowed',
    },
})

function ButtonOutline(props: ButtonProps) {
    const classes = useStyle()
    return (
        <button
            {...props}
            className={`${classes.root} ${props.disabled ? classes.disabled : classes.active} ${props.className}`}
        >
            {props?.children}
        </button>
    )
}

export default ButtonOutline
