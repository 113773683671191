import theme from '@/theme'
import React from 'react'
import { CSSProperties } from 'styled-components'
import ErrorIcon from '../Icon/svg/ErrorIcon'

export default function (props: { style?: CSSProperties }) {
    return (
        <div
            style={{
                color: theme.colors.error,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: 14,
                display: 'flex',
                alignItems: 'center',
                columnGap: 10,
                ...props.style,
            }}
        >
            <ErrorIcon />
            未入力項目があります
        </div>
    )
}
