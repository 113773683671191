import OutlineButton from '@/components/atoms/Button/OutlineButton'
import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import CustomModal from '@/components/atoms/CustomModal'
import { makeStyles } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import React, { useEffect, useState } from 'react'
import 'react-tooltip/dist/react-tooltip.css'

const useStyles = makeStyles({
    titleArea: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'black',
        margin: '20px 50px',
    },
    textAreaWrapper: {
        padding: '0 0 20px 0',
        textAlign: 'center',
    },
    formBtnGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 30,
    },
})

export default function EditMemoModal(
    props: { 
        title: string,
        initialMemo: string,
        open: boolean,
        onSave: (memo: string) => void,
        onDelete: () => void,
        onClose: () => void,
     }
) {
    const [memo, setMemo] = useState("")
    const [deletedMemo, setDeletedMemo] = useState(false)
    const [showConfirmMemo, setShowConfirmMemo] = useState(false)
    const [processing, setProcessing] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        setMemo(props.initialMemo)
    }, [props.initialMemo])

    const isEnteredMemo = () => {
        return props.initialMemo != null && props.initialMemo.trim() !== ''
    }

    const renderConfirmingMemo = () => {
        const lines = memo.split('\n')
        const converted = lines.map((line, index) => {
            if (line.match(/https?:\/\/\S+/g)) {
                const parts = line.split(/(https?:\/\/\S+)/g)
                return (
                    <React.Fragment key={index}>
                        {parts.map((part, partIndex) => {
                            if (partIndex % 2 === 1) {
                                return (
                                    <a href={part} key={partIndex} target={'_blank'}>
                                        {part}
                                    </a>
                                )
                            } else {
                                return (
                                    <React.Fragment key={partIndex}>
                                        {part}
                                    </React.Fragment>
                                )
                            }
                        })}
                        <br />
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                )
            }
        })

        return <div>{converted}</div>
    }

    const handleClose = () => {
        props.onClose()
        setShowConfirmMemo(false)
        setDeletedMemo(false)
    }

    const handleSave = () => {
        if (processing) {
            return
        }
        setProcessing(true)
        try {
            props.onSave(memo)
            setShowConfirmMemo(true)
        } catch (err) {
            console.log(err)
        } finally {
            setProcessing(false)
        }
    }

    const handleDelete = () => {
        if (processing) {
            return
        }
        setProcessing(true)
        try {
            props.onDelete()
            setDeletedMemo(true)
            setShowConfirmMemo(true)
        } catch (err) {
            console.log(err)
        } finally {
            setProcessing(false)
        }
    }

    return(
        <CustomModal
            title={deletedMemo 
                ? 'メモが削除されました' 
                : showConfirmMemo ? 'メモが保存されました' : 'メモ入力'}
            open={props.open}
            onClose={() => handleClose()}
            width={'960px'}
            height={'440px'}>
            <div>
                <div className={classes.titleArea}>
                    {props.title}
                </div>
                <div className={classes.textAreaWrapper}>
                    {showConfirmMemo ?
                        <div style={{
                            background: '#F7F7F7',
                            fontSize: '14px',
                            textAlign: 'left',
                            display: 'inline-block',
                            margin: '0',
                            padding: '15px 20px',
                            width: '800px',
                            height: '240px',
                            overflowY: 'scroll'
                        }}>
                            {renderConfirmingMemo()}
                        </div>
                        :
                        <TextareaAutosize
                            style={{
                                fontSize: '14px',
                                maxWidth: '800px',
                                minWidth: '800px',
                                minHeight: "240px",
                                maxHeight: "240px",
                                overflowY: 'scroll'
                            }}
                            onChange={(e) => setMemo(e.target.value)}
                            value={memo} />
                    }
                </div>
                {showConfirmMemo ?
                    <div className={classes.formBtnGroup}>
                        <OutlineButton
                            style={{ width: 120, height: 35, fontSize: 14 }}
                            onClick={() => handleClose()}>
                            閉じる
                        </OutlineButton>
                    </div>
                    :
                    <div className={classes.formBtnGroup}>
                        <OutlineButton
                            style={{ width: 120, height: 35, fontSize: 14 }}
                            onClick={() => handleClose()}>
                            キャンセル
                        </OutlineButton>
                        <PrimaryButton
                            style={{ display: isEnteredMemo() ? 'block' : 'none' }}
                            width={120}
                            height={35}
                            fontSize={14}
                            onClick={handleDelete}
                            colorType={'danger'}>
                            削除する
                        </PrimaryButton>
                        <PrimaryButton
                            width={120}
                            height={35}
                            fontSize={14}
                            onClick={handleSave}>
                            保存する
                        </PrimaryButton>
                    </div>
                }
            </div>
        </CustomModal>
    )
}
