import theme from '@/theme'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles({
    tableContainer: {
        width: 'fit-content',
        margin: '0 auto',
    },
    cellHeader: {
        fontWeight: 600,
        border: '3px solid #fff',
        color: theme.colors.white,
        backgroundColor: theme.colors.primaryN,
        wordSpacing: 999,
        whiteSpace: 'pre-line',
    },
    fistCellBody: {
        fontWeight: 600,
        borderTop: '2px solid #fff',
        borderRight: '2px solid #fff',
        backgroundColor: '#D8E3EA',
    },
    cell: {
        fontSize: 14,
    },
})

export default function InputScopeInfoTable() {
    const classes = useStyle()
    const headers = [
        { id: 1, text: '機種', width: 400 },
        { id: 2, text: 'HFCの種類', width: 193 },
        { id: 3, text: '使用時排出係数 (年間漏洩率)', width: 150 },
    ]

    const data = [
        { id: 1, name: '小型冷凍冷蔵機器(内蔵型等)', value: 'R-404A、HFC-134a等', percent: '2%' },
        { id: 2, name: '別置型ショーケース', value: 'R-404A、R-407C等', percent: '16% ' },
        { id: 3, name: '中型冷凍冷蔵機器(除、別置型ショーケース)', value: 'R-404A、R-407C等', percent: '13〜17%' },
        { id: 4, name: '大型冷凍機', value: 'HFC-134a、R404A等', percent: '7〜12%' },
        { id: 5, name: 'ビル用パッケージエアコン', value: 'R-410A、R-407C等', percent: '3.5%' },
        {
            id: 6,
            name: 'その他業務用空調機器(除、ビル用パッケージエアコン)',
            value: 'R-410A、R-407C等',
            percent: '3〜5%',
        },
    ]

    return (
        <TableContainer className={classes.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map((h) => (
                            <TableCell
                                key={h.id}
                                width={h.width}
                                align="center"
                                className={`${classes.cell} ${classes.cellHeader}`}
                            >
                                {h.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((d) => (
                        <TableRow key={d.id}>
                            <TableCell className={`${classes.cell} ${classes.fistCellBody}`}>{d.name}</TableCell>
                            <TableCell className={classes.cell}>{d.value}</TableCell>
                            <TableCell align="center" className={classes.cell}>
                                {d.percent}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
