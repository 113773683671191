import OutlineButton from '@/components/atoms/Button/OutlineButton'
import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import InputTree from '@/components/organisms/InputTree'
import ItemInputs from '@/components/organisms/ItemInputForm'
import { PLAN_ID } from '@/constant/options'
import { InputTreeProvider, useInputTreeContext } from '@/contexts/InputTree'
import { InputStatus, makeMonth, siteActivityApi, siteSubmissionApi } from '@/ghgApi'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { Activities, Activity, EmissionFactor, SaveActivity, SubmissionStatus } from '@/openapi/api'
import { ROUTES } from '@/routes'
import theme from '@/theme'
import { handleScrollToTop } from '@/utils/scrollHelper'
import { InputTreeActivity, InputTreeNode, InputTreeNodeEvents } from '@/utils/tree'
import { InputDate, InputTreeProviderProps } from '@/zustand/slice/inputSlice'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import React, { SetStateAction, useEffect, useMemo, useState } from 'react'
import IncompleteWarningText from '../../atoms/IncompleteWarningText'
import CSVHandler from './CSVHandler'

const useStyle = makeStyles({
    treeCsvWrapper: {
        marginTop: 20,
    },
    tableWrapper: {
        padding: '10px 30px 50px',
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.colors.lightGray,
    },
    btnWrapper: {
        textAlign: 'center',
        marginTop: 50,
    },
})

const styles = {
    button: {
        width: 480,
        height: 48,
        fontSize: 20,
        fontWeight: 300,
    },
}

const ItemSelector = (props: {
    tree: InputTreeNode
    categoryId: number
    activities: Activity[]
    emissionFactors: EmissionFactor[]
    scope3categoryName?: string
    onComplete?: () => void
    setActivities: React.Dispatch<SetStateAction<Activity[] | undefined>>
    scopeNumber: number
}) => {
    const { storeState, inputScopeState, setInputScopeState } = useStore()
    const { inputTreeState, setInputTreeState } = useInputTreeContext()
    // =================================================================================
    // API Calls
    // =================================================================================
    const [loading, setLoading] = useState(false)
    const [hasEmissionFactors, setHasEmissionFactors] = useState(inputTreeState.tree.hasEmissionFactors)
    const [hasActivities, setHasActivities] = useState(false)
    const [ready, setReady] = useState(false)
    const [prevActivities, setPrevActivities] = useState<Activities['activities']>([])
    const [sessionDate] = useStateWithSessionStorage<InputDate | null>(SessionStorageKey.DATA_INPUT_DATE, null)
    const classes = useStyle()
    const siteId = useMemo(() => storeState.selectedSite?.id, [storeState.selectedSite?.id])
    const markReady = ((e: CustomEvent) => {
        if (!e.detail.error) {
            setHasEmissionFactors(true)
        } else console.warn(e.detail.error)
    }) as EventListener

    useEffect(() => {
        const tree = inputTreeState.tree

        tree.target.addEventListener(InputTreeNodeEvents.EMISSION_FACTORS_GET, markReady)
        return () => tree.target.removeEventListener(InputTreeNodeEvents.EMISSION_FACTORS_GET, markReady)
    }, [])

    const fetchData = async () => {
        if (siteId && !hasActivities && inputScopeState.date) {
            const eYear = sessionDate?.year || inputScopeState.date.year
            const eMonth = sessionDate?.month || inputScopeState.date.month
            const month = makeMonth(eYear, eMonth)
            const dayjsDate = dayjs(new Date(eYear, eMonth - 1, 1)).subtract(1, 'month')
            const prevMonth = makeMonth(dayjsDate.year(), dayjsDate.month() + 1)
            try {
                const currentMonthResponse = await siteActivityApi.getSiteActivities(siteId, month, props.categoryId)
                const prevMonthResponse = await siteActivityApi.getSiteActivities(siteId, prevMonth, props.categoryId)

                const currActivities = currentMonthResponse.data.activities
                const prevActivities = prevMonthResponse.data.activities
                setPrevActivities(prevActivities)
                setHasActivities(true)
                if (currActivities.length) {
                    const tree = inputTreeState.tree
                    tree.setAllSelectedFromActivities(currActivities)
                    tree.setAllActivities(currActivities)
                    setInputTreeState({ ...inputTreeState, tree, selected: tree.getAllSelected() })
                    return
                }
                if (prevActivities.length) {
                    const activitiesWithoutQuantity = prevActivities.map((a) => ({ ...a, quantity: null }))
                    inputTreeState.tree.setAllSelectedFromActivities(activitiesWithoutQuantity)
                    inputTreeState.tree.setAllActivities(activitiesWithoutQuantity)
                    setInputTreeState({ ...inputTreeState, selected: inputTreeState.tree.getAllSelected() })
                }
            } catch (err) {
                console.log(err)
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [storeState.selectedSite])

    useEffect(() => {
        setReady(hasEmissionFactors && hasActivities)
    }, [hasEmissionFactors, hasActivities])

    useEffect(() => {
        setInputTreeState({ ...inputTreeState, complete: inputTreeState.tree.isComplete })
    }, [inputTreeState.selected])

    async function handleInputSubmit() {
        setLoading(true)
        if (storeState.selectedSite) {
            const currentMonth = makeMonth(
                sessionDate?.year || inputScopeState.date.year,
                sessionDate?.month || inputScopeState.date.month,
            )
            const emissionFactorIds = props.emissionFactors.map((eF) => eF.id)
            const newActivities: Activity[] = []
            const currActivities: SaveActivity[] = props.activities.map((activity) => {
                const deleteFlg = emissionFactorIds.includes(activity.emissionFactorId) ? 0 : 1
                if (!deleteFlg) {
                    newActivities.push(activity)
                }
                return {
                    ...activity,
                    quantity: activity.quantity || 0,
                    deleteFlg,
                    month: currentMonth,
                    id: activity.id,
                }
            })
            const replaceSiteActivities: Activity[] = []
            inputTreeState.tree
                .getAllActivities()
                .filter((at) => emissionFactorIds.includes(at.emissionFactorId))
                .forEach(({ id, ...activity }: InputTreeActivity) => {
                    const isDeleted = currActivities.find((curr) => curr.id === id && curr.deleteFlg === 1)
                    const isEditMode = dayjs(activity?.month).isSame(currentMonth, 'month')
                    const result = { ...activity, quantity: activity.quantity || 0 }
                    if (!isDeleted) {
                        if (isEditMode) {
                            replaceSiteActivities.push({ id, ...result })
                        } else {
                            replaceSiteActivities.push(result)
                        }
                    }
                })

            try {
                if (!siteId) return
                const deletedActivities = currActivities.filter((activity) => activity.deleteFlg === 1)
                // delete unnecessary activities
                if (deletedActivities.length) {
                    await siteActivityApi.saveSiteActivities(siteId, currentMonth, { activities: deletedActivities })
                    props.setActivities(newActivities)
                }
                // submit activities
                await siteActivityApi.replaceSiteActivities(
                    storeState.selectedSite.id,
                    currentMonth,
                    props.categoryId,
                    { activities: replaceSiteActivities },
                )
                // save site submissions
                await siteSubmissionApi.saveSiteSubmissions(storeState.selectedSite.id, {
                    month: currentMonth,
                    status: SubmissionStatus.Done,
                })

                if (replaceSiteActivities.length) {
                    inputScopeState.inputStatus[inputTreeState.categoryId] = InputStatus.DONE
                } else {
                    inputScopeState.inputStatus[inputTreeState.categoryId] = null
                }

                const currentTabValue = inputScopeState.tabValue
                const isBlockScope3 =
                    storeState.organization?.planId && storeState.organization?.planId === PLAN_ID.starter
                const maxTabValue = isBlockScope3 ? 1 : 2
                const isStay = (currentTabValue === 1 && isBlockScope3) || currentTabValue === maxTabValue
                const nextTabValue = () => {
                    if (isStay) {
                        return currentTabValue
                    }
                    return currentTabValue + 1
                }
                const tabValue = nextTabValue()
                setInputScopeState({
                    ...inputScopeState,
                    tabValue,
                    hasChanges: true,
                })
                if (props.onComplete) props.onComplete()
                setLoading(false)
                if ((isBlockScope3 && inputTreeState.categoryId === 2) || inputTreeState.categoryId === 17)
                    navigate(ROUTES.INPUT_MONTH)
                handleScrollToTop()
            } catch (err) {
                setLoading(false)
                console.log(err)
                throw Error('OrganizationId or siteId is null')
            }
        }
    }

    return ready ? (
        <>
            <div className={classes.treeCsvWrapper}>
                <InputTree />
                {/* {Boolean(inputTreeState.selected.length) && <CSVHandler scopeNumber={props.scopeNumber} />} */}
                <CSVHandler scopeNumber={props.scopeNumber} />
            </div>
            {Boolean(inputTreeState.selected.length) && (
                <div className={classes.tableWrapper}>
                    <ItemInputs
                        selectedLeafs={inputTreeState.selected}
                        prevActivities={prevActivities}
                        scope3categoryName={props.scope3categoryName}
                    />
                    <div style={{ textAlign: 'center', margin: '50px 0 -20px' }}>
                        {!inputTreeState.complete && <IncompleteWarningText />}
                    </div>
                    <div className={classes.btnWrapper}>
                        <PrimaryButton
                            onClick={handleInputSubmit}
                            disabled={loading}
                            style={{
                                ...styles.button,
                                color: theme.colors.white,
                            }}
                        >
                            入力を保存する
                        </PrimaryButton>
                    </div>
                </div>
            )}
            <div className={classes.btnWrapper}>
                <OutlineButton style={{ ...styles.button }} onClick={() => navigate(ROUTES.INPUT_MONTH)}>
                    戻る
                </OutlineButton>
            </div>
        </>
    ) : (
        <div className="mt-20">loading...</div>
    )
}

interface ItemSelector {
    activities: Activity[]
    emissionFactors: EmissionFactor[]
    scope3categoryName?: string
    onComplete?: () => void
    setActivities: React.Dispatch<SetStateAction<Activity[] | undefined>>
    scopeNumber: number
}
export default (props: Omit<InputTreeProviderProps, 'children'> & ItemSelector) => {
    //TODO: tobe improved by zustand store
    // const { inputTreeState, setInputTreeState } = useStore()
    // useEffect(() => {
    //     if (inputTreeState.categoryId !== props.categoryId) {
    //         setInputTreeState({
    //             ...inputTreeState,
    //             selected: [],
    //             tree: props.tree,
    //             categoryId: props.categoryId,
    //         })
    //     }
    // }, [props.tree, props.categoryId])

    return (
        <InputTreeProvider tree={props.tree} categoryId={props.categoryId}>
            <div style={{ width: '100%', maxWidth: theme.contentContainer.width }}>
                <ItemSelector
                    onComplete={props.onComplete}
                    tree={props.tree}
                    categoryId={props.categoryId}
                    activities={props.activities}
                    emissionFactors={props.emissionFactors}
                    setActivities={props.setActivities}
                    scope3categoryName={props.scope3categoryName}
                    scopeNumber={props.scopeNumber}
                />
            </div>
        </InputTreeProvider>
    )
}
