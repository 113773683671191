import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import MemoEnteredIconWhite from '@/components/atoms/Icon/svg/MemoEnteredIconWhite'
import MemoUnEnteredIconWhite from '@/components/atoms/Icon/svg/MemoUnEnteredIconWhite'
import { makeMonth, siteCategoryTransactionApi } from '@/ghgApi'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { Activities, SiteCategoryTransaction } from '@/openapi'
import theme from '@/theme'
import { InputTreeLeafNode } from '@/utils/tree'
import { InputDate, InputTreeState } from '@/zustand/slice/inputSlice'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/styles'
import EditMemoModal from './EditMemoModal'
import ItemInputForm from './ItemInputForm'
import SearchModal from './SearchModal'
import React, { useEffect, useMemo, useState } from 'react'

const useStyle = makeStyles({
    tableWrapper: {
        width: '100%',
        maxWidth: 1237,
        minHeight: 100,
        marginTop: 40,
        border: `solid 1px ${theme.colors.greyText}`,
        overflow: 'hidden',
        backgroundColor: theme.colors.white,
    },
    title: {
        fontWeight: 300,
        fontSize: 16,
        paddingLeft: 10,
    },
    tabHeading: {
        padding: '15px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: 20,
    },
    form: {
        padding: '0 30px',
    },
    formBtnGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 30,
    },
})

export type ItemInput = {
    leaf: InputTreeLeafNode
    scope3categoryName?: string
    siteCategoryTransaction?: SiteCategoryTransaction
} & Pick<ItemInputs, 'prevActivities'>

function ItemInput(props: ItemInput) {
    const { tableWrapper, title, tabHeading, form, formBtnGroup } = useStyle()
    const { emissionFactorTableNodes, inputScopeState, storeState } = useStore()
    const [sessionDate] = useStateWithSessionStorage<InputDate | null>(SessionStorageKey.DATA_INPUT_DATE, null)
    const [showEditMemoModal, setShowEditMemoModal] = useState(false)
    const [memo, setMemo] = useState("")
    const siteId = useMemo(() => storeState.selectedSite?.id, [storeState.selectedSite?.id])
    const eFTableNode = emissionFactorTableNodes.find(
        (eF) => eF.emissionFactorTableId === props.leaf.emissionFactorTableId && eF.id === props.leaf.id,
    )
    const text = `${props.scope3categoryName ? `${props.scope3categoryName} > ` : ''}${
        eFTableNode?.parent ? `${eFTableNode.parent} > ` : ''
    }${eFTableNode?.name || ''}`

    useEffect(() => {
        const initialMemo = props.siteCategoryTransaction?.memo
        setMemo(initialMemo ? initialMemo : "")
    }, [props.siteCategoryTransaction?.memo])

    const isEnteredMemo = () => {
        return memo && memo.trim() !== ''
    }

    const handleEditMemoClick = () => {
        if (!showEditMemoModal) {
            setShowEditMemoModal(true)
        }
    }

    const handleClose = () => {
        setShowEditMemoModal(false)
    }

    const handleSave = (memo: string) => {
        if (!siteId) {
            return
        }
        saveSiteCategoryTransaction(memo)
    }

    const handleDelete = () => {
        if (!siteId) {
            return
        }
        saveSiteCategoryTransaction('')
    }

    const saveSiteCategoryTransaction = async (savingMemo: string) => {
        const currentMonth = makeMonth(
            sessionDate?.year || inputScopeState.date.year,
            sessionDate?.month || inputScopeState.date.month,
        )
        try {
            await siteCategoryTransactionApi.saveSiteCategoryTransaction(
                siteId!,
                currentMonth,
                {
                    categoryEmissionFactorTableId: props.leaf.id,
                    month: currentMonth,
                    memo: savingMemo,
                },
            )
            setMemo(savingMemo)
        } catch (err) {
            throw Error('failed to save siteCategoryTransaction.')
        }
    }

    return (
        <div className={tableWrapper}>
            <div className={tabHeading}>
                {eFTableNode ? <span className={title}>{text}</span> : null}
                <div className={formBtnGroup}>
                    <EditMemoModal
                        title={text}
                        initialMemo={memo}
                        open={showEditMemoModal}
                        onSave={handleSave}
                        onDelete={handleDelete}
                        onClose={handleClose} />
                    <PrimaryButton
                        startIcon={isEnteredMemo() ? <MemoEnteredIconWhite /> : <MemoUnEnteredIconWhite />}
                        style={{ width: 164, height: 35, fontSize: 14, fontWeight: 300 }}
                        onClick={handleEditMemoClick}>
                        {isEnteredMemo() ? 'メモを編集する' : 'メモを追加する'}
                    </PrimaryButton>
                    <SearchModal leaf={props.leaf} title={text} />
                </div>
            </div>
            <div className={form}>
                <ItemInputForm
                    leaf={props.leaf}
                    prevActivities={props.prevActivities}
                    scope3categoryName={props.scope3categoryName}
                />
            </div>
        </div>
    )
}

interface ItemInputs {
    selectedLeafs: InputTreeState['selected']
    prevActivities: Activities['activities']
    scope3categoryName?: string
}
const ItemInputs = (props: ItemInputs) => {
    const { inputScopeState, storeState } = useStore()
    const [sessionDate] = useStateWithSessionStorage<InputDate | null>(SessionStorageKey.DATA_INPUT_DATE, null)
    const [siteCategoryTransactionList, setSiteCategoryTransactionList] = useState([] as SiteCategoryTransaction[])
    const siteId = useMemo(() => storeState.selectedSite?.id, [storeState.selectedSite?.id])

    useEffect(() => {
        fetchData()
    }, [storeState.selectedSite])

    const fetchData = async () => {
        if (!siteId) {
            return
        }
        const currentMonth = makeMonth(
            sessionDate?.year || inputScopeState.date.year,
            sessionDate?.month || inputScopeState.date.month,
        )
        const categoryIdList = props.selectedLeafs.map((l) => l.id)
        try {
            const siteCategoryTransactionList = await siteCategoryTransactionApi.getSiteCategoryTransactions(
                siteId,
                currentMonth,
                {
                    categoryIdList: categoryIdList,
                }
            )
            setSiteCategoryTransactionList(siteCategoryTransactionList.data.siteCategoryTransactionList)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {props.selectedLeafs.map((leaf) => (
                <ItemInput
                    leaf={leaf}
                    key={leaf.title}
                    prevActivities={props.prevActivities}
                    scope3categoryName={props.scope3categoryName}
                    siteCategoryTransaction={siteCategoryTransactionList.find((t) => t.categoryEmissionFactorTableId == leaf.id)}
                />
            ))}
        </>
    )
}

export default ItemInputs
