import { Category } from '@/openapi/api'
import { InputTreeLeafNode, InputTreeNode } from '@/utils/tree'
import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'

// ==============================================================================
// Types
// ==============================================================================
export type InputTreeState = {
    categoryId: Category['id']
    tree: InputTreeNode
    selected: Array<InputTreeLeafNode>
    complete: boolean
}

interface IInputTreeContext {
    inputTreeState: InputTreeState
    setInputTreeState: Dispatch<SetStateAction<InputTreeState>>
}

export type InputTreeProviderProps = {
    children: ReactNode
    categoryId: Category['id']
    tree: InputTreeNode
}

// ==============================================================================
// Context
// ==============================================================================
const InputTreeContext = createContext<IInputTreeContext | null>(null)

export const initInputTreeContext = (tree: InputTreeNode, categoryId: Category['id']): IInputTreeContext => {
    const [inputTreeState, setInputTreeState] = useState<InputTreeState>({
        categoryId: categoryId,
        tree: tree,
        selected: [],
        complete: false,
    })

    return { inputTreeState, setInputTreeState }
}

/**
 *  nullチェック付きのコンテクスト
 *  参考: https://juliangaramendy.dev/blog/strict-react-context
 */
export const useInputTreeContext: () => IInputTreeContext = () => {
    const context = useContext(InputTreeContext)
    if (context === null) throw new Error('InputTreeContext must be used within InputTreeProvider')
    else return context
}

export const InputTreeProvider = (props: InputTreeProviderProps) => {
    const initialContext = initInputTreeContext(props.tree, props.categoryId)

    return <InputTreeContext.Provider value={initialContext}>{props.children}</InputTreeContext.Provider>
}
