import OutlineButton from '@/components/atoms/Button/OutlineButton'
import CustomModal from '@/components/atoms/CustomModal'
import InputScopeInfoTable from '@/components/template/Analysis/Tables/InputScopeInfoTable'
import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles({
    content: {
        textAlign: 'center',
    },
    desc: {
        margin: '20px 0',
        fontSize: 14,
    },
})

export default function ScopeInfoModal(props: { open: boolean; onClose: () => void }) {
    const classes = useStyle()
    return (
        <CustomModal open={props.open} onClose={props.onClose} width={960} title="算定方法「拠点の冷媒の漏洩」について">
            <div className={classes.content}>
                <div className={classes.desc}>
                    以下を参考に、HFCの使用量×使用時排出係数で計算した数字を入力ください。
                </div>
                <InputScopeInfoTable />
                <div className={classes.desc}>
                    出典：日本国温室効果ガスインベントリ報告書 2017年4月 温室効果ガスインベントリオフィス編
                </div>
                <OutlineButton style={{ width: 120, height: 35, fontSize: 14 }} onClick={props.onClose}>
                    閉じる
                </OutlineButton>
            </div>
        </CustomModal>
    )
}
